

import { Exwarehouse } from '@/types/material'
import { Vue, Component } from 'vue-property-decorator'

@Component({ name: 'ExwarehouseDetail' })
export default class extends Vue {
  formData: Exwarehouse = {
    projectId: '',
    warehouseId: '',
    projectName: '',
    warehouseName: '',
    checkoutDate: '',
    checkoutUser: '',
    checkoutUserName: '',
    checkoutExplain: '',
    detailedList: []
  }

  created () {
    this.loadData()
  }

  get id () {
    return this.$route.query.id as string
  }

  loadData () {
    this.$axios.get(this.$apis.material.selectCheckoutById, {
      id: this.id
    }).then(res => {
      this.formData = res
    })
  }
}
